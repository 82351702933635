import PricelistService from '../../services/pricelists.service';

const initialState = {
    pricelists: [],
    isFetching: true,
    currentPricelist: null,
    currentPricelistMetadata: null,
    modals: {
        isAddModalOpen: false,
        isNewTemplateModalOpen: false
    }
}

export const pricelists = {
    namespaced: true,
    state: initialState,
    actions: {
        fetchPricelists({commit}) {
            commit('FETCHING_PRICELISTS');

            return PricelistService.getPriceLists().then((response) => {
                commit('PRICELISTS_FETCHED', response.data);
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
            })
        },
        uploadPricelist({commit}, data) {
            return PricelistService.uploadPriceList(data).then((response) => {
                commit('PRICELIST_UPLOADED', response.data);
                commit('ADD_MODAL_CLOSED');
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
            });
        },
        uploadCustomPricelist({commit}, data) {
            return PricelistService.uploadCustomPricelist(data).then((response) => {
                commit('PRICELIST_UPLOADED', response.data);
                commit('CUSTOM_TEMPLATE_MODAL_CLOSED');
                return Promise.resolve(response);
            }, (error) => {
                console.log(error.toString());
            });
        },
        deletePricelist({commit}, id) {
           let decision = window.confirm("This will delete this Pricelist and remove all records. Are you sure?");

           if (decision) {
               return PricelistService.deletePriceList(id).then(() => {
                   commit('PRICELIST_DELETED');
               }, (error) => {
                   console.log(error.toString());
               });
           }
        },
        reProcessPricelist({commit}, id) {
          let decision = window.confirm("This will reset the matches in this pricelist and re-process it. Any over-rides or manual re-assignments will be deleted! Are you sure?");

          if (decision) {
              return PricelistService.reProcessPricelist(id).then(() => {
                  commit('PRICELIST_UPDATED');
              }, (error) => {
                  console.log(error.toString());
              });
          }
        },
        fetchPriceListMetadata({commit}, id) {
            const data =  PricelistService.getPriceListMetadata(id)

            data.then((response) => {
                commit('PRICELIST_METADATA_FETCHED', response.data);
            });
        },
        openAddModal({commit}) {
            commit("ADD_MODAL_OPENED");
        },
        closeAddModal({commit}) {
            commit("ADD_MODAL_CLOSED");
        },
        openCustomAddModal({commit}) {
            commit("CUSTOM_TEMPLATE_MODAL_OPENED");
        },
        closeCustomAddModal({commit}) {
            commit("CUSTOM_TEMPLATE_MODAL_CLOSED");
        },
    },
    mutations: {

        FETCHING_PRICELISTS(state) {
            state.isFetching = true;
        },
        PRICELISTS_FETCHED(state, pricelists) {
            state.pricelists = pricelists.data;
            state.isFetching = false;
        },
        PRICELIST_UPLOADED(state, priceListInfo) {
            state.currentPricelist = priceListInfo;
            state.isWorking = false;
        },
        PRICELIST_UPDATED(state) {
            state.isWorking = false;
        },
        PRICELIST_DELETED(state) {
            state.isWorking = false;
        },
        ADD_MODAL_OPENED(state) {
            state.modals.isAddModalOpen = true;
        },
        ADD_MODAL_CLOSED(state) {
            state.modals.isAddModalOpen = false;
        },
        CUSTOM_TEMPLATE_MODAL_OPENED(state) {
            state.modals.isNewTemplateModalOpen = true;
        },
        CUSTOM_TEMPLATE_MODAL_CLOSED(state) {
            state.modals.isNewTemplateModalOpen = false;
        },
        PRICELIST_METADATA_FETCHED(state, metadata) {
            state.currentPricelistMetadata = metadata;
        }
    }
}