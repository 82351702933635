import axios from "axios";
import store from '../store';
import router from '../router';

const API_URL =  process.env.VUE_APP_API_BASE || "http://localhost:8002/api/";
const BACKEND_URL =  process.env.VUE_APP_BACKEND_BASE || "http://localhost:8002";

function generateHeaders(requestHeaders = {}) {
    let user = JSON.parse(localStorage.getItem('latina-sam-user'));
    let finalHeaders = {};

    if (user && user.token) {
       finalHeaders =   {...finalHeaders, Authorization: 'Bearer ' + user.token};
    }

    return {...finalHeaders, requestHeaders};
}

function handleError(error) {
    if (error.response.status === 401) {
        store.dispatch('auth/unauthorized');
        router.push('/login');
    }
}

function formatUrl(endpoint) {

    try {
        const instance = JSON.parse(window.localStorage.getItem('spirit2Instance'));

        if (instance.code === 'hnb') {
            return API_URL + 'hnb/' + endpoint
        }

        return API_URL + endpoint

    } catch (e) {
        console.log("Error making API call to: " + endpoint)
        return API_URL + endpoint;
    }
}

export default {
    apiUrl: API_URL,
    backendUrl: BACKEND_URL,
    get(endpoint, params, requestHeaders = {}) {
        return axios.get(formatUrl(endpoint), {headers: generateHeaders(requestHeaders), params: params}).then(res => res.data).catch((error) => handleError(error));
    },
    post(endpoint, body, requestHeaders = {}) {
        return axios.post(formatUrl(endpoint), body, {headers: generateHeaders(requestHeaders)}).then(res => res.data).catch((error) => handleError(error));
    },
    put(endpoint, body) {
        return axios.put(formatUrl(endpoint), body, {headers: generateHeaders()}).then(res => res.data).catch((error) => handleError(error));
    },
    patch(endpoint, body) {
        return axios.patch(formatUrl(endpoint), body, {headers: generateHeaders()}).then(res => res.data).catch((error) => handleError(error));
    },
    delete(endpoint) {
        return axios.delete(formatUrl(endpoint), {headers: generateHeaders()}).then(res => res.data).catch((error) => handleError(error));
    }
}